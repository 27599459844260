import React, {useState} from 'react';

const Header = (): JSX.Element => {

  const [active,setActive] = useState(false)

  const showMenu = () => {
      setActive(!active)
  }

  return (
    <nav className="flex items-center justify-between flex-wrap bg-teal p-3 bg-black">
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <a href="#responsive-header" className="font-semibold text-xl tracking-tight">
          Sergio Cordero
        </a>
      </div>
      <div className="block lg:hidden">
        <button onClick={showMenu} className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
          <svg className="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>
              Menu
            </title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className={active ? 
                        "w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto" : 
                        "hidden w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto"}>
        <div className="text-sm lg:flex-grow text-center">
          <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter text-white mr-4">
            Sobre mí
          </a>
          <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter text-white mr-4">
            Blog
          </a>
        </div>
        <div className="text-center">
          <a href="#" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal hover:text-black hover:bg-white mt-4 lg:mt-0">
            Hablemos
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;

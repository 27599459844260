import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import PostWidget from '../PostWidget';
import BlogList from '../BlogList';
import { BlogListItemProps } from '../BlogListItem';

export interface IndexProps {
  posts: BlogListItemProps[];
  pinnedPosts: BlogListItemProps[];
  firstPage: boolean;
  lastTimestamp?: string;
}

const Index = (props: IndexProps): JSX.Element => {
  return (
    <>
      <Header></Header>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 pt-12">
        <h1 className="text-3xl font-extrabold pt-12 pb-12">
          {props.firstPage ? 'Latest Posts' : 'Posts'}
        </h1>
        <div className="pb-24">
          <BlogList items={props.posts}></BlogList>
          {props.lastTimestamp && (
            <a href={`?loadFrom=${props.lastTimestamp}`}>Cargar más →</a>
          )}
        </div>
      </div>
      {/* <div className="lg:col-span-4 col-span-1">
        <div className="lg:sticky relative top-8">
          <PostWidget categories={[]} slug=''/>
        </div>
      </div> */}
      <Footer></Footer>
    </>
  );
};

export default Index;

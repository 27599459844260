import React from 'react';
import TagList from './TagList';

export interface BlogListItemProps {
  title: string;
  datePublished: string;
  tags?: string;
  path: string;
  coverImage?: string;
  summary: string;
}

/**
 * based on https://tailwind-nextjs-starter-blog.vercel.app/
 */
const BlogListItem = (props: BlogListItemProps): JSX.Element => {
  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setHydrated(true);
  }, []);
  return (
    <div className="bg-white shadow-lg rounded-lg p-0 lg:p-8 pb-12 mb-8">
      <div className="relative overflow-hidden shadow-md pb-80 mb-6">
        <img
          src={props.coverImage}
          alt=""
          className="object-top absolute h-80 w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg"
        />
      </div>

      <h1 className="transition duration-700 text-center mb-8 cursor-pointer hover:text-pink-600 text-3xl font-semibold">
        <a href={`/${props.path}`}>{props.title}</a>
      </h1>
      <div className="block lg:flex text-center items-center justify-center mb-8 w-full">
        <div className="font-medium text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline mr-2 text-pink-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span className="align-middle">
            {hydrated
              ? new Date(props.datePublished).toLocaleDateString()
              : new Date(props.datePublished).toUTCString()}
          </span>
        </div>
      </div>
      <p className="text-center text-lg text-gray-700 font-normal px-4 lg:px-20 mb-8">
        {props.summary}
      </p>
      <div className="text-center">
        <a href={`/${props.path}`}>
          <span className="transition duration-500 ease transform hover:-translate-y-1 inline-block bg-pink-600 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer">
            Continue Reading
          </span>
        </a>
      </div>
    </div>
  );
};

export default BlogListItem;

import React from 'react';

function Wrapped<PropType>({
  Component,
}: {
  Component: React.FunctionComponent<PropType>;
}): React.FunctionComponent<PropType> {
  return function Wrapper(props: PropType): JSX.Element {
    return (
      <div className="bg-gradient-to-r from-cyan-500 to-blue-500">
        <Component {...props}></Component>
      </div>
    );
  };
}

export default Wrapped;
